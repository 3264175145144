'use strict';

// Constants
var SELECTOR = {
    COMPONENT: '.experience-component',
    HOMEPAGE: '.storepage[id^="homepage"]',
    CLASS: {
        VISIBLE: 'experience-visible'
    }
};

/**
 * Gets element position
 * @param {JQuery<HTMLElement>} $element - the element to reveal
 * @returns {boolean} is element in position
 */
function isRevealable($element) {
    const windowHeight = $(window).height();
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + windowHeight;
    const elemTop = $element.offset().top;
    const elemBottom = elemTop + $element.height();

    return elemBottom >= docViewTop && elemTop <= docViewBottom;
}

/**
 * Reveals element on page
 */
function reveal() {
    $(SELECTOR.COMPONENT).each(function () {
        if (isRevealable($(this))) {
            $(this).addClass(SELECTOR.CLASS.VISIBLE);
        }
    });
}

// Document ready functions
$(document).ready(function () {
    // Reveal elements when scrolling
    if (SELECTOR.HOMEPAGE) {
        // Initially reveal elements in view on page load
        reveal();
        $(window).on('scroll', function () {
            reveal();
        });
    }
});
